import React, { useCallback, useEffect, useState } from 'react';
import Dropdown from '../Globals/Dropdown';
import { Tick } from '../Icons/Tick';
import { SMART_CONNECT_FEATURES } from '../../types';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux/store';
import { activateExternalStreamDispatch, deactivateExternalStreamDispatch } from '../../redux/actions/application';
import { usePrevious } from '../../helper/hooks';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import './SmartConnect.scss';
import { replaceText } from '../../helper/helper';
import { sendSetFeatureFocus, sendToggleExternalStreaming } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';
import { activateSessionHandover, deactivateSessionHandover } from '../../redux/slices/sessionHandlingSlice';

export default function SmartConnect() {
    const externalStreamIsActive = useSelector((state: RootState) => state.application.externalStreamIsActive);
    const sessionHandoverIsActive = useSelector((state: RootState) => state.sessionHandlingSlice.sessionHandoverIsActive);
    const smartConnectIsActive = useSelector((state: RootState) => state.application.smartConnectIsActive);
    const texts = useSelector((state: RootState) => state.texts.texts);

    const [currentFeature, setCurrentFeature] = useState('');
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const prevFeature = usePrevious(currentFeature);

    const activateExternalStreaming = useCallback(async () => {
        setButtonIsDisabled(true);
        activateExternalStreamDispatch();
        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
    }, []);

    const deactivateExternalStreaming = useCallback(() => {
        deactivateExternalStreamDispatch();
        sendToggleExternalStreaming(false);
    }, []);

    const toggleSessionHandover = () => {
        addNotificationAndShowDispatch('session.handover.waiting', 'info', DISPLAY_ONLY_IN_SESSION);
        setButtonIsDisabled(true);
        store.dispatch(activateSessionHandover());
    };

    const handleSelect = async id => {
        switch (true) {
            case id === SMART_CONNECT_FEATURES.EXTERNAL_STREAM:
                setCurrentFeature(SMART_CONNECT_FEATURES.EXTERNAL_STREAM);
                setButtonIsDisabled(false);
                break;
            case id === SMART_CONNECT_FEATURES.SESSION_HANDOVER:
                setCurrentFeature(SMART_CONNECT_FEATURES.SESSION_HANDOVER);
                setButtonIsDisabled(false);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (currentFeature === SMART_CONNECT_FEATURES.EXTERNAL_STREAM && currentFeature !== prevFeature) {
            // we need a timeout here, since the messages can be sent in the wrong order
            // and we need to wait for the correct detection of the new caller device
            setTimeout(() => {
                store.dispatch(deactivateSessionHandover());
            }, 500)
        }
    }, [currentFeature, prevFeature]);

    useEffect(() => {
        if (currentFeature === SMART_CONNECT_FEATURES.SESSION_HANDOVER && currentFeature !== prevFeature) {
            deactivateExternalStreaming();
        }
    }, [currentFeature, prevFeature, deactivateExternalStreaming]);

    useEffect(() => {
        if (!smartConnectIsActive && currentFeature.length !== 0) {
            if (externalStreamIsActive) deactivateExternalStreaming();
            if (sessionHandoverIsActive) {
                // we need a timeout here, since the messages can be sent in the wrong order
                // and we need to wait for the correct detection of the new caller device
                setTimeout(() => {
                    store.dispatch(deactivateSessionHandover());
                }, 500)
            }
            setCurrentFeature('');
        }
    }, [smartConnectIsActive, currentFeature, sessionHandoverIsActive, externalStreamIsActive, deactivateExternalStreaming]);

    useEffect(() => {
        if (currentFeature === SMART_CONNECT_FEATURES.SESSION_HANDOVER && !sessionHandoverIsActive) {
            setButtonIsDisabled(false);
        }
    }, [currentFeature, sessionHandoverIsActive]);

    useEffect(() => {
        if (currentFeature === SMART_CONNECT_FEATURES.EXTERNAL_STREAM && !externalStreamIsActive) {
            setButtonIsDisabled(false);
        }
    }, [currentFeature, externalStreamIsActive]);

    return (
        <div className="smart-connect">
            <div>
                <Dropdown
                    minHeight={20}
                    clickHandler={handleSelect}
                    label={currentFeature.length === 0 ? currentFeature : replaceText(texts, `smartConnect.${currentFeature}`)}
                    disabled={!smartConnectIsActive}>
                    <SmartConnectFeatures currentFeature={currentFeature} />
                </Dropdown>
                {currentFeature === SMART_CONNECT_FEATURES.SESSION_HANDOVER && (
                    <button className="btn btn--primary" onClick={() => toggleSessionHandover()} disabled={currentFeature.length === 0 || buttonIsDisabled}>
                        {replaceText(texts, 'qrcode.generate')}
                    </button>
                )}
                {currentFeature === SMART_CONNECT_FEATURES.EXTERNAL_STREAM && (
                    <button className="btn btn--primary" onClick={() => activateExternalStreaming()} disabled={currentFeature.length === 0 || buttonIsDisabled}>
                        {replaceText(texts, 'qrcode.generate')}
                    </button>
                )}
            </div>
        </div>
    );
}

type SmartConnectFeaturesProps = {
    smartConnectFeatures?: string[];
    currentFeature?: string;
    clickElement?: any;
};

const SmartConnectFeatures = ({ currentFeature, clickElement }: SmartConnectFeaturesProps) => {
    const texts = useSelector((state: RootState) => state.texts.texts);

    return (
        <div>
            {Object.keys(SMART_CONNECT_FEATURES).map((feature, id) => (
                <div
                    className="smart-connect__features"
                    key={id}
                    onClick={() => {
                        clickElement(feature);
                    }}>
                    <span>{texts[`smartConnect.${SMART_CONNECT_FEATURES[feature]}`]}</span>
                    <span>{currentFeature === feature && <Tick />}</span>
                </div>
            ))}
        </div>
    );
};
